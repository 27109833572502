<template>
  <div>
    <div v-if="inviteList.length > 0" class="invite-count">
      {{ slideNumber + 1 }}/{{ inviteList.length }}
    </div>
    <div class="container">
      <div v-if="!loading" class="add-btn btn active" @click="addInviteCode">
        增加二维码
      </div>
      <div class="qrcode-items">
        <a-carousel ref="carousel" :after-change="onChange">
          <div
            v-for="(invite, idx) in inviteList"
            :key="idx"
            class="qrcode-item"
          >
            <div class="title">注册人数 {{ invite.inviteNum }}</div>
            <div class="qrcode btn active">
              <div class="qrcode-inner">
                <qrcode-vue
                  :value="invite.invite_address"
                  level="H"
                  size="140"
                />
              </div>
            </div>
            <div class="desc">扫描邀请新用户加入</div>
          </div>
        </a-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'invite',
  props: [],
  components: {
    QrcodeVue
  },
  data() {
    return {
      member_id: '',
      inviteList: [],
      pic_url: '',
      nick_name: '',
      slideNumber: 0,
      loading: true
    }
  },
  methods: {
    async addInviteCode() {
      const mapStr = JSON.stringify({})
      const { code, info } = await post(
        'addInviteCode.do',
        this.Qs.stringify({ mapStr })
      )
      if (code === 'fail') {
        this.$message.error(info, 2)
      } else {
        await this.getInviteCodeList()
        if (this.inviteList.length > 1) {
          setTimeout(() => {
            this.$refs.carousel.goTo(this.inviteList.length - 1)
          }, 500)
        }
      }
    },
    async getInviteCodeList() {
      const mapStr = JSON.stringify({})
      this.loading = true
      try {
        const { code, data, info } = await post(
          'getInviteCodeList.do',
          this.Qs.stringify({ mapStr })
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { member_id, inviteList, pic_url, nick_name } = JSON.parse(data)
          this.member_id = member_id
          this.inviteList = inviteList
          this.pic_url = pic_url
          this.nick_name = nick_name
        }
      } catch {
      } finally {
        this.loading = false
      }
    },
    onChange(slideNumber) {
      this.slideNumber = slideNumber
    }
  },
  async mounted() {
    await this.getInviteCodeList()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 2rem;
  width: 70%;
}

.add-btn {
  color: white;
  border: none;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  letter-spacing: 0.2rem;
  font-weight: bold;
  flex-shrink: 0;
  user-select: none;
}

.qrcode-items {
  width: 250px;
}

.qrcode-item {
  width: unset !important;
}

.ant-carousel {
  ::v-deep .slick-dots-bottom {
    bottom: -12px;
  }
}

.invite-count {
  color: #c9a67c;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 2rem;
}

.title {
  font-size: 1rem;
  color: white;
  margin-bottom: 1rem;
}

.qrcode {
  padding: 1.2rem;
  border-radius: 10px;
  cursor: default;

  .qrcode-inner {
    padding: 1rem 0.9rem;
    border-radius: 10px;
    background-color: #111;
  }

  img {
    width: 7.5rem;
    height: 7rem;
    background-color: white;
  }
}

.desc {
  font-size: 0.8rem;
  color: white;
  margin-top: 1rem;
  letter-spacing: 4px;
}

@include md {
  .container {
    justify-content: center;
    margin-top: 5rem;
  }

  .add-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border-radius: 5px;
  }

  .invite-count {
    left: 0.5rem;
    top: 0.5rem;
    right: auto;
  }
}
</style>
